footer{
    position: relative;
    top: 60px;
    background-color:#232F3E;
}

footer .footer_container{
    color: #fff;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
    align-items:flex-start;
    position: relative;
    top: 30px;
}

footer .footer_container .footr_details_one h3{
    font-size: 16px;
    text-align: left;
}

footer .footer_container .footr_details_one p{
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 12px;
    letter-spacing: .5px;
    text-align: left;
    cursor: pointer;
}


footer .lastdetails{
    background-color: #131A22;
    height:20vh;
}

footer h1{
    display: block;
    width: 80px;
    margin: 50px auto auto auto;
    text-align: center;
    padding-top: 30px;
}

footer p{
    color: #fff;
    font-size: 12px;
    letter-spacing: .3px;
    text-align: center;
    margin-top: 20px;
}


@media (max-width:700px){
    .footer_container .forres{
        display: none;
    }
   
}
@media (max-width:500px){
    .footer_container .forres{
        display: none;
    }
}